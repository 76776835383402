import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export enum ProjectIconTypes {
  PenFancy = 'PenFancy',
  Trophy = 'Trophy',
  MugHot = 'MugHot',
  SackDollar = 'SackDollar',
  PaperclipVertical = 'PaperclipVertical',
  FloppyDisk = 'FloppyDisk',
  BusinessTime = 'BusinessTime',
  BullseyeArrow = 'BullseyeArrow',
  Envelope = 'Envelope',
}

export function BaseProjectIcon({
  iconType,
  color,
  size,
  width,
}: {
  iconType?: ProjectIconTypes;
  color?: string;
  size?: SizeProp;
  width?: number;
}): JSX.Element {
  const icon: IconDefinition = require(`@fortawesome/pro-solid-svg-icons/fa${
    iconType || ProjectIconTypes.Trophy
  }`)?.definition;

  return <FontAwesomeIcon icon={icon} color={color} size={size} width={width} />;
}
