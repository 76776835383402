export const domain = 'IntegrationsModel'

export const onInit = `${domain}/onInit`

export const onStartIntegration = `${domain}/onStartIntegration`
export const onFetchIntegrations = `${domain}/onFetchIntegrations`

export const onBatchIntegrations = `${domain}/onBatchIntegrations`
export const onUpdateIntegration = `${domain}/onUpdateIntegration`
export const onCreateIntegration = `${domain}/onCreateIntegration`

export const onConfirmIntegrationsStatus = `${domain}/onConfirmIntegrationsStatus`
export const onConfirmIntegrationsStatusSuccess = `${domain}/onConfirmIntegrationsStatusSuccess`

export const onSetIntegrationStatus = `${domain}/onSetIntegrationStatus`
