export const domain = 'Search';

export const searchTasks = `${domain}/searchTasks`;
export const searchTasksSuccess = `${domain}/searchTasksSuccess`;
export const searchTasksFailure = `${domain}/searchTasksFailure`;
export const searchTasksClear = `${domain}/searchTasksClear`;
export const searchTasksShowEntireProject = `${domain}/searchTasksShowEntireProject`;

export const searchProjects = `${domain}/searchProjects`;
export const searchProjectsSuccess = `${domain}/searchProjectsSuccess`;
export const searchProjectsFailure = `${domain}/searchProjectsFailure`;
export const searchProjectsClear = `${domain}/searchProjectsClear`;

export const searchProjectsAndTasks = `${domain}/searchProjectsAndTasks`;

export const searchUsers = `${domain}/searchUsers`;
export const searchUsersSuccess = `${domain}/searchUsersSuccess`;
export const searchUsersFailure = `${domain}/searchUsersFailure`;
export const searchUsersClear = `${domain}/searchUsersClear`;

export const searchFiles = `${domain}/searchFiles`;
