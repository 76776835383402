import React, { Suspense, FC } from 'react';

import AppLoaderToggle from 'commonComponents/AppLoaderToggle';

export type SuspenseComponentInteface = {
  fallback?: JSX.Element;
  disableLoader?: boolean;
};

const SuspenseComponent: FC<SuspenseComponentInteface> = ({
  children,
  fallback,
  disableLoader = false,
}) => (
  <Suspense
    fallback={disableLoader ? <div></div> : fallback || <AppLoaderToggle />}
  >
    {children}
  </Suspense>
);

export default SuspenseComponent;
