export const domain = 'CreateSpaceModel'

export const onChangeSpaceName = `${domain}/onChangeSpaceName`
export const onChangeSpacePurpose = `${domain}/onChangeSpacePurpose`
export const onAddSpaceMember = `${domain}/onAddSpaceMember`
export const onRemoveSpaceMember = `${domain}/onRemoveSpaceMember`
export const onChangeSpaceColor = `${domain}/onChangeSpaceColor`
export const onChangeSpaceIsPrivate = `${domain}/onChangeSpaceIsPrivate`
export const onChangeIsCurrentUserFollowingSpace = `${domain}/onChangeIsCurrentUserFollowingSpace`

export const onResetCreateSpaceData = `${domain}/onResetCreateSpaceData`