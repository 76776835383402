
import handleError from '../../utils/handleError'
import { doNothing } from '../../utils'

export default class CheckoutClient {

  constructor(config) {
    this.openCheckout = this.openCheckout.bind(this)
    this.config = config
  }

  init() {
    try {
      if (!window.Chargebee) {
        throw new Error('Chargebee client not initialized')
      }

      if (!this.config.paymentSite) {
        throw new Error('paymentSite not set')
      }

      this.client = window.Chargebee.init({ site: this.config.paymentSite })
    } catch (error) {
      handleError(error)
    }
  }

  openCheckout(
    paymentUrl,
    onPaymentSuccess,
    onPaymentClose,
    onPaymentFailure = doNothing,
    onPaymentStep = doNothing,
  ) {
    this.client.openCheckout({
      url: paymentUrl,
      loaded: doNothing,
      error: function (error) {
        handleError(error)
        onPaymentFailure(error)
      },
      close: function () {
        onPaymentClose()
      },
      success: function (paymentPageId) {
        onPaymentSuccess(paymentPageId)
      },
      step: function (currentStep) {
        onPaymentStep(currentStep)
      },
    })
  }

}
