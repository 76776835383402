import handleError from '../common/utils/handleError';
import firebase from 'firebase/app';
import 'firebase/messaging';
import { PushNotificationClientInterface } from './PushNotificationClientInterface';
import {
  PushNotificationClientOptions,
  FirebaseMessage,
  TokenRefreshListener,
  DeviceInfo,
  FirebaseMessageData,
} from './types';
import { LoggerInterface } from '../common/interfaces/LoggerInterface';
import { DeviceType } from '../common/models/domain/NotificationsModel/types';
import { Maybe } from '../common/types';
import { getPushNotificationFromRawMessage } from './utils';

const SERVICE_WORKER_CLICK_EVENT = 'notificationClick';
const defaultOptions = {
  onNotification: console.log,
  onNotificationDisplayed: console.log,
  onNotificationOpened: console.log,
  onTokenRefresh: console.log,
  canShowForegroundNotification: () => true,
};

export default class FirebaseClient implements PushNotificationClientInterface {
  private options: PushNotificationClientOptions;
  private logger: LoggerInterface;
  private messaging: firebase.messaging.Messaging;

  constructor(config, logger = console) {
    this.options = { ...defaultOptions, ...config };
    this.logger = logger;
  }

  init = async () => {
    const config = {
      ...this.options,
      messagingSenderId: this.options.senderID,
    };
    try {
      firebase.initializeApp(config);
      this.messaging = firebase.messaging();
      this.messaging.usePublicVapidKey(this.options.vapidKey);
      this.messaging.onMessage(this.onMessage.bind(this));
      this.subscribeBackgroundNotificationClick();
    } catch (error) {
      handleError(error);
    }
  };

  onMessage(message: FirebaseMessage): void {
    const data = getPushNotificationFromRawMessage(message);
    if (!this.options.canShowForegroundNotification(data)) {
      return;
    }
    var notification = new Notification(message.notification.data.title, {
      body: message.notification.data.body,
      icon: '../../commonAssets/favicon.png',
      data,
    });
    const rawNotification = message.notification.data as FirebaseMessageData;
    notification.onclick = () => {
      this.options.onNotificationOpened(rawNotification);
    };
  }

  subscribeBackgroundNotificationClick = () => {
    navigator.serviceWorker.onmessage = (event) => {
      var { data } = event;

      switch (data.command) {
        case SERVICE_WORKER_CLICK_EVENT: {
          try {
            const notification = JSON.parse(data.message);
            this.options.onNotificationOpened(notification);
          } catch (error) {
            this.logger.error(error);
          }
          break;
        }
        default:
          break;
      }
    };
  };

  onTokenRefresh = (
    listener: TokenRefreshListener = this.options.onTokenRefresh
  ): void => {
    if (!this.messaging) {
      return;
    }
    this.messaging.onTokenRefresh(() => {
      this.messaging
        .getToken()
        .then((refreshedToken) => listener(refreshedToken))
        .catch((error) => this.logger.error(error));
    });
  };

  getDeviceInfo = async (): Promise<Maybe<DeviceInfo>> => {
    if (!this.messaging) {
      throw new Error('messaging not initialized');
    }
    try {
      let deviceId = await this.messaging.getToken();
      if (!deviceId) {
        await this.requestPermission();
        deviceId = await this.messaging.getToken();
      }
      return {
        deviceId,
        deviceType: DeviceType.BROWSER,
      };
    } catch (error) {
      this.logger.error(error);
      return null;
    }
  };

  requestPermission = async (): Promise<void> => {
    if (!this.messaging) {
      throw new Error('messaging not initialized');
    }
    return await this.messaging.requestPermission();
  };
}
