
import { PushNotification, FirebaseConfigInterface } from './types';
import { AppEventType } from '../common/types';

function onNotificationOpened(notification: PushNotification) {
  const { AppEventEmitter } = require('common/services')
  AppEventEmitter.emit(AppEventType.PUSH_NOTIFICATION_CLICKED, notification)
}

export default function getPushNotificationClient(config: FirebaseConfigInterface) {
  if (process.env.STORYBOOK_DEVICE !== 'browser') {
    const { canShowForegroundMobileNotification } = require('./utils')
    const isIOS = require('../HeySpaceMobile/utils/isIOS').default
    try {
      if (isIOS) {
        const FirebaseIOSClient = require('./FirebaseIOSClient').default
        return new FirebaseIOSClient({
          ...config,
          onNotificationOpened: onNotificationOpened,
          canShowForegroundNotification: canShowForegroundMobileNotification,
        })
      } else {
        const FirebaseAndroidClient = require('./FirebaseAndroidClient').default
        return new FirebaseAndroidClient({
          ...config,
          onNotificationOpened: onNotificationOpened,
          canShowForegroundNotification: canShowForegroundMobileNotification,
        })
      }
    } catch (error) {
      console.log(error)
      console.log(new Error('Could not import FirebaseNativeClient'))
    }
  } else {
    try {
      const FirebaseClient = require('./FirebaseClient').default
      const { canShowForegroundWebNotification } = require('./utils')
      return new FirebaseClient(
        {
          ...config,
          onNotificationOpened: onNotificationOpened,
          canShowForegroundNotification: canShowForegroundWebNotification,
        },
      )
    } catch (error) {
      console.log(new Error('Could not import FirebaseClient'))
    }
  }
}
