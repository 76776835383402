import { saveAs } from 'file-saver'

const saveFile = (fileBlob: Blob, filename: string): void => {
  saveAs(fileBlob, filename)
}

export const saveCSVFile = (csv: string, filename:string): void => {
  const fileBlob = new Blob([csv], { type: 'text/csv;charset=utf-8' })
  saveFile(fileBlob, filename)
}

export default saveFile
