export { default as Add } from '-!svg-react-loader?name=Add!commonComponents/Icons/add.svg';
export { default as AlertCircle } from '-!svg-react-loader?name=AlertCircle!commonComponents/Icons/alert-circle.svg';
export { default as AlertTriangle } from '-!svg-react-loader?name=AlertTriangle!commonComponents/Icons/alert-triangle.svg';
export { default as AlignLeft } from '-!svg-react-loader?name=AlignLeft!commonComponents/Icons/align-left.svg';
export { default as Arrow } from '-!svg-react-loader?name=Arrow!commonComponents/Icons/arrow.svg';
export { default as ArrowDown } from '-!svg-react-loader?name=ArrowDown!commonComponents/Icons/arrow-down.svg';
export { default as ArrowLeft } from '-!svg-react-loader?name=ArrowLeft!commonComponents/Icons/arrow-left.svg';
export { default as ArrowRight } from '-!svg-react-loader?name=ArrowRight!commonComponents/Icons/arrow-right.svg';
export { default as ArrowTopRight } from '-!svg-react-loader?name=ArrowTopRight!commonComponents/Icons/arrow-top-right.svg';
export { default as ArrowUp } from '-!svg-react-loader?name=ArrowUp!commonComponents/Icons/arrow-up.svg';
export { default as ArrowUpRightFromSquare } from '-!svg-react-loader?name=ArrowUpRightFromSquare!commonComponents/Icons/arrow-up-right-from-square.svg';
export { default as AssignUser } from '-!svg-react-loader?name=AssignUser!commonComponents/Icons/assign-user.svg';
export { default as AtSign } from '-!svg-react-loader?name=AtSign!commonComponents/Icons/at-sign-2.svg';
export { default as Bell } from '-!svg-react-loader?name=Bell!commonComponents/Icons/bell.svg';
export { default as Calendar } from '-!svg-react-loader?name=Calendar!commonComponents/Icons/calendar.svg';
export { default as Card } from '-!svg-react-loader?name=Card!commonComponents/Icons/card.svg';
export { default as Chat } from '-!svg-react-loader?name=Chat!commonComponents/Icons/icon-chat.svg';
export { default as CheckCircle } from '-!svg-react-loader?name=CheckCircle!commonComponents/Icons/check-circle.svg';
export { default as Checklist } from '-!svg-react-loader?name=Checklist!commonComponents/Icons/checklist.svg';
export { default as Checkmark } from '-!svg-react-loader?name=Checkmark!commonComponents/Icons/checkmark.svg';
export { default as ChevronDown } from '-!svg-react-loader?name=ChevronDown!commonComponents/Icons/chevron-down.svg';
export { default as ChevronLeft } from '-!svg-react-loader?name=ChevronLeft!commonComponents/Icons/chevron-left.svg';
export { default as ChevronRight } from '-!svg-react-loader?name=ChevronRight!commonComponents/Icons/chevron-right.svg';
export { default as ChevronUp } from '-!svg-react-loader?name=ChevronUp!commonComponents/Icons/chevron-up.svg';
export { default as Clock } from '-!svg-react-loader?name=Clock!commonComponents/Icons/clock.svg';
export { default as CommentSlash } from '-!svg-react-loader?name=CommentSlash!commonComponents/Icons/comment-slash.svg';
export { default as Copy } from '-!svg-react-loader?name=Copy!commonComponents/Icons/copy.svg';
export { default as Computer } from '-!svg-react-loader?name=Download!commonComponents/Icons/computer.svg';
export { default as CornerUpRight } from '-!svg-react-loader?name=CornerUpRight!commonComponents/Icons/corner-up-right.svg';
export { default as Delete } from '-!svg-react-loader?name=Delete!commonComponents/Icons/delete.svg';
export { default as Description } from '-!svg-react-loader?name=Description!commonComponents/Icons/description.svg';
export { default as Download } from '-!svg-react-loader?name=Download!commonComponents/Icons/download.svg';
export { default as Dropbox } from '-!svg-react-loader?name=Download!commonComponents/Icons/dropbox.svg';
export { default as Droplet } from '-!svg-react-loader?name=Droplet!commonComponents/Icons/droplet.svg';
export { default as Edit } from '-!svg-react-loader?name=Edit!commonComponents/Icons/edit.svg';
export { default as Emot } from '-!svg-react-loader?name=Emot!commonComponents/Icons/emot.svg';
export { default as Exclamation } from '-!svg-react-loader?name=Exclamation!commonComponents/Icons/exclamation.svg';
export { default as Blind } from '-!svg-react-loader?name=Eye!commonComponents/Icons/blind.svg';
export { default as Eye } from '-!svg-react-loader?name=Eye!commonComponents/Icons/eye.svg';
export { default as CSVFile } from '-!svg-react-loader?name=File!commonComponents/Icons/csv-file.svg';
export { default as File } from '-!svg-react-loader?name=File!commonComponents/Icons/file-7.svg';
export { default as Filters } from '-!svg-react-loader?name=Filters!commonComponents/Icons/filters.svg';
export { default as Folder } from '-!svg-react-loader?name=Folder!commonComponents/Icons/folder.svg';
export { default as Follow } from '-!svg-react-loader?name=Follow!commonComponents/Icons/follow.svg';
export { default as Gif } from '-!svg-react-loader?name=Gif!commonComponents/Icons/gif.svg';
export { default as GoogleDrive } from '-!svg-react-loader?name=Gif!commonComponents/Icons/google-drive.svg';
export { default as Happy } from '-!svg-react-loader?name=Happy!commonComponents/Icons/happy.svg';
export { default as Info } from '-!svg-react-loader?name=Info!commonComponents/Icons/info-7.svg';
export { default as InfoCircle } from '-!svg-react-loader?name=InfoCircle!commonComponents/Icons/info.svg';
export { default as Kanban } from '-!svg-react-loader?name=Kanban!commonComponents/Icons/icon-kanban.svg';
export { default as LogOut } from '-!svg-react-loader?name=LogOut!commonComponents/Icons/log-out-4.svg';
export { default as Limit } from '-!svg-react-loader?name=Maximize!commonComponents/Icons/limit.svg';
export { default as Maximize } from '-!svg-react-loader?name=Maximize!commonComponents/Icons/maximize.svg';
export { default as MessageRespond } from '-!svg-react-loader?name=Maximize!commonComponents/Icons/message-respond.svg';
export { default as Wrench } from '-!svg-react-loader?name=Maximize!commonComponents/Icons/wrench.svg';
export { default as Members } from '-!svg-react-loader?name=Members!commonComponents/Icons/icon-members.svg';
export { default as Minimize } from '-!svg-react-loader?name=Minimize!commonComponents/Icons/minimize.svg';
export { default as BackBurger } from '-!svg-react-loader?name=MoreVertical!commonComponents/Icons/back-burger.svg';
export { default as ForwardBurger } from '-!svg-react-loader?name=MoreVertical!commonComponents/Icons/forward-burger.svg';
export { default as MoreVertical } from '-!svg-react-loader?name=MoreVertical!commonComponents/Icons/more-vertical.svg';
export { default as Move } from '-!svg-react-loader?name=Move!commonComponents/Icons/move.svg';
export { default as PaperClip } from '-!svg-react-loader?name=PaperClip!commonComponents/Icons/paperclip-1.svg';
export { default as PaymentBreadcrumb } from '-!svg-react-loader?name=PaymentBreadcrumb!./payment.svg';
export { default as Phone } from '-!svg-react-loader?name=Phone!commonComponents/Icons/phone.svg';
export { default as PhoneAndroid } from '-!svg-react-loader?name=PhoneAndroid!commonComponents/Icons/phone-android.svg';
export { default as Planet } from '-!svg-react-loader?name=Planet!commonComponents/Icons/icon-planet.svg';
export { default as PlanetWithLock } from '-!svg-react-loader?name=PlanetWithLock!commonComponents/Icons/planet-with-lock.svg';
export { default as Plus } from '-!svg-react-loader?name=Plus!commonComponents/Icons/plus.svg';
export { default as Points } from '-!svg-react-loader?name=Points!commonComponents/Icons/points.svg';
export { default as Private } from '-!svg-react-loader?name=Private!commonComponents/Icons/icon-private.svg';
export { default as Public } from '-!svg-react-loader?name=Public!commonComponents/Icons/public.svg';
export { default as Puzzle } from '-!svg-react-loader?name=Puzzle!commonComponents/Icons/puzzle.svg';
export { default as RadioChecked } from '-!svg-react-loader?name=RadioChecked!commonComponents/Icons/radio-checked.svg';
export { default as RadioNotChecked } from '-!svg-react-loader?name=RadioNotChecked!commonComponents/Icons/radio-not-checked.svg';
export { default as Refresh } from '-!svg-react-loader?name=Refresh!commonComponents/Icons/refresh-cw-2.svg';
export { default as Repeat } from '-!svg-react-loader?name=Repeat!commonComponents/Icons/repeat-alt-solid.svg';
export { default as Search } from '-!svg-react-loader?name=Search!commonComponents/Icons/search.svg';
export { default as Send } from '-!svg-react-loader?name=Send!commonComponents/Icons/send-2.svg';
export { default as Settings } from '-!svg-react-loader?name=Settings!commonComponents/Icons/settings.svg';
export { default as Share } from '-!svg-react-loader?name=Share!commonComponents/Icons/share.svg';
export { default as Shield } from '-!svg-react-loader?name=Shield!commonComponents/Icons/shield.svg';
export { default as ShoppingBag } from '-!svg-react-loader?name=ShoppingBag!commonComponents/Icons/shopping-bag.svg';
export { default as SubscriptionChoiceBreadcrumb } from '-!svg-react-loader?name=SubscriptionChoiceBreadcrumb!./subscription-choice.svg';
export { default as SubscriptionDetailsBreadcrumb } from '-!svg-react-loader?name=SubscriptionDetailsBreadcrumb!./subscription-details.svg';
export { default as Tags } from '-!svg-react-loader?name=Tags!commonComponents/Icons/tags.svg';
export { default as ThreeDotsHorizontal } from '-!svg-react-loader?name=ThreeDotsHorizontal!commonComponents/Icons/three-dots.svg';
export { default as Telescope } from '-!svg-react-loader?name=Telescope!commonComponents/Icons/telescope.svg';
export { default as ThreeDotsVertical } from '-!svg-react-loader?name=ThreeDotsVertical!commonComponents/Icons/three-dots-vertical.svg';
export { default as Tick } from '-!svg-react-loader?name=Tick!commonComponents/Icons/tick.svg';
export { default as TransferArrow } from '-!svg-react-loader?name=TransferArrow!commonComponents/Icons/transferArrow.svg';
export { default as Trash } from '-!svg-react-loader?name=Trash!commonComponents/Icons/trash.svg';
export { default as TrashCan } from '-!svg-react-loader?name=TrashCan!commonComponents/Icons/trashcan.svg';
export { default as Video } from '-!svg-react-loader?name=Video!commonComponents/Icons/video.svg';
export { default as WifiOff } from '-!svg-react-loader?name=WifiOff!commonComponents/Icons/wifi-off.svg';
export { default as X } from '-!svg-react-loader?name=X!commonComponents/Icons/x.svg';
export { default as XIcon } from '-!svg-react-loader?name=XIcon!commonComponents/Icons/x_icon.svg';
