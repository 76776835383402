var config = {
  "restApiUri": "https://planner.timecamp.com/api",
  "activityFeedUri": "wss://planner.timecamp.com/feed",
  "activityFeedReconnectionDelay": 5000,
  "backgroundResourcesMaxDelay": 20000,
  "googleClientId": "980452382491-akebdelqbqc2aul3eo050g9cj5gu2elr.apps.googleusercontent.com",
  "sentryDSN": "https://d3a5fe7eba304fb282fe0feab0229758@sentry.timecamp.io/6",
  "intercomAppId": "a9oqydcu",
  "customerMessagingPlatformApiKey": "cP1y8m",
  "isTrackingEnabled": true,
  "userComApiKey": "1IlJ1poyzHE5w84veCKB9dJ26Xai4IIAMZeVt2X5yLvns42ecLoTskmJnp4ZahI7",
  "amazonResizeUrl": "https://tocpjptk01.execute-api.eu-central-1.amazonaws.com/dev/resize?key=",
  "landingPageUrl": "https://timecamp.com/planner",
  "fcm": {
    "senderID": "980452382491",
    "androidChannelId": "heyspace",
    "androidChannelName": "Heyspace",
    "vapidKey": "BGYmlaG2aAeVC2yAZFg_58ZLPRiKxuDTDv4TQan_HKh3ZjN4xZlrPuHqPcIue-9JBI2QnKSM6oH7Z7H2dzLgwrA",
    "apiKey": "AIzaSyCa-zKCkdexo1TZg3AFmo1BuIHq_ogfwOk",
    "authDomain": "heyspace-dce00.firebaseapp.com",
    "databaseURL": "https://heyspace-dce00.firebaseio.com",
    "projectId": "heyspace-dce00",
    "storageBucket": "heyspace-dce00.appspot.com",
    "appId": "1:980452382491:web:c50b5560a17909f6d865be"
  },
  "payment": {
    "site": "timecamp"
  }
}

window.__tc3_config = config;

if (typeof module !== 'undefined') {
  module.exports = config;
}
