
export const domain = 'OAuthModel'

export const onAuthorizeOAuth2 = `${domain}/onAuthorizeOAuth2`

export const onLoadOAuthClientData = `${domain}/onLoadOAuthClientData`
export const onLoadOAuthClientDataSuccess = `${domain}/onLoadOAuthClientDataSuccess`

export const onBatchClientsData = `${domain}/onBatchClientsData`

export const onRedirectToAuthorizeOAuth1 = `${domain}/onRedirectToAuthorizeOAuth1`
export const onSetIsOAuth1Authorized = `${domain}/onSetIsOAuth1Authorized`
export const onFetchOAuth1AuthorizedApplications = `${domain}/onFetchOAuth1AuthorizedApplications`
export const onFetchOAuth1AuthorizedApplicationsIfDidNotFetchAlready = `${domain}/onFetchOAuth1AuthorizedApplicationsIfDidNotFetchAlready`
export const onSetOAuth1AuthorizedApplications = `${domain}/onSetOAuth1AuthorizedApplications`

export const onAuthorizeOAuth1 = `${domain}/onAuthorizeOAuth1`
export const onDeauthorizeOAuth1 = `${domain}/onDeauthorizeOAuth1`
