// eslint-disable-next-line import/no-unresolved
import { theme } from '../../heySpace/theme/app-theme';

/* 2020 StyleGuide */
export const trueGreenColor = '#199800';
export const maudlinOysterColor = '#c0c0c0';
export const haplessSnowColor = '#e2e2e2';
/* - - - - - - - - */

export const whiteColor = '#f1f1f1';
export const blackColor = '#000';
export const black05Color = 'rgba(0, 0, 0, 0.05)';
export const black10Color = 'rgba(0, 0, 0, 0.1)';
export const black25Color = 'rgba(0, 0, 0, 0.25)';
export const black20Color = 'rgba(0, 0, 0, 0.2)';
export const black40Color = 'rgba(0, 0, 0, 0.4)';
export const black60Color = 'rgba(0, 0, 0, 0.6)';
export const whiteTwoColor = '#ffffff';
export const whiteThreeColor = '#d8d8d8';
export const whiteFourColor = '#f9f9f9';
export const whiteTwo8Color = 'rgba(255, 255, 255, 0.08)';
export const dodgerBlueColor = '#2f8fff';
export const blueGreyColor = '#7088a1';
export const blueyGreyColor = '#a3afbc';
export const blueyGrey60Color = 'rgba(112, 136, 161, 0.6)';
export const clearBlueColor = '#1584fc';
export const cloudyBlueColor = '#b9c3ce';
export const darkGreyBlueColor = '#283949';
export const seafoamBlueColor = '#57bec7';
export const lightBlueColor = '#5ccff5';
export const babyBlueColor = '#b7d8ff';
export const cloudyBlue20Color = 'rgba(185, 195, 206, 0.2)';
export const cloudyBlue30Color = 'rgba(185, 195, 206, 0.3)';
export const cloudyBlue50Color = 'rgba(185, 195, 206, 0.5)';
export const slate60Color = 'rgba(86, 104, 122, 0.6)';
export const lightGreyColor = '#f8f9f9';
export const warmGreyColor = '#979797';
export const warmGrey5Color = 'rgba(126, 126, 126, 0.05)';
export const warmGrey15Color = 'rgba(126, 126, 126, 0.15)';
export const lightGreenColor = '#42ffc0';
export const pinkishGreyColor = '#c8c8c8';
export const coralPinkColor = '#ff5c73';
export const macaroniAndCheeseColor = '#f8c233';
export const lipstickColor = '#e94149';
export const softPinkColor = '#f79dd0';
export const darkPeachColor = '#67b868';
export const cornFlowerColor = '#7850fc';
export const mediumPinkColor = '#e9579e';
export const paleRedColor = '#e54b4b';
export const darkTwoColor = '#1f2e3d';
export const silverColor = '#e4e5e5';
export const slateColor = '#56687a';
export const mortarColor = 'rgba(91, 91, 91, 0.07)';
export const wisteriaColor = '#2d80eb';
export const wisteria15Color = '#2466bc';
export const wisteria15FlatColor = '#6ca6f1';
export const wisteria50Color = '#1b4d8d';
export const purpleHeartColor = '#922EBF';
export const silverTwoColor = '#dedfdf';
export const darkGreyBlueTwoColor = '#293c4f';
export const paleGreyColor = '#f3f3f4';
export const darkBlueGreyColor = '#111f2d';
export const darkThreeColor = '#1a2939';
export const brownishGreyColor = '#636363';
export const greyish50Color = 'rgba(177, 177, 177, 0.5)';
export const slateTwoColor = '#435567';
export const mangoColor = '#ffb02b';
export const darkMintColor = '#5cc984';
export const dodgerBlueTwoColor = '#56a0f6';
export const coralPinkTwoColor = '#ff575f';
export const duskColor = '#49627b';
export const gossamerColor = '#0ba765';
export const jungleGreenColor = '#25bc7c';
export const jetStreamColor = '#b2d5c9';
export const ghostColor = '#c6cbd1';
export const ironColor = '#dfe2e5';
export const aquaHazeColor = '#f6f8fa';
export const lochmaraColor = '#0576B9';
export const bahamaBlueColor = '#005E99';
export const alertSuccessBackground = '#ccf5da';
export const alertSuccessFontColor = '#009a59';
export const warmGreyTwoColor = '#8f8f8f';
export const white82Color = 'rgba(248, 248, 248, 0.82)';
export const brightBlueColor = '#007aff';
export const steelColor = '#8e8e93';
export const iOSSettingsBackground = '#F4F8FE';
export const iOSSettingsHeaderTextColor = '#9b9b9b';
export const iOSSettingsSecondaryTextColor = '#d1d1d6';
export const iOSSettingsDividerColor = '#E2E2E2';
export const brightRedColor = '#ff0c18';
export const altoColor = '#d9d9d9';
export const grayColor = '#858585';
export const amethystColor = '#ae65c5';
export const silverChaliceColor = '#b2b2b2';

export const tagsColors = Object.entries(theme.color.decorative)
  .filter(([key]) => key.includes('100'))
  .map(([_, color]) => color);
