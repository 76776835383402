exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"whiteTwoColor": "#ffffff",
	"whiteSevenColor": "#f8f8f8",
	"whiteFiveColor": "#eaeaea",
	"cloudyBlueColor": "#b9c3ce",
	"warmGreyThreeColor": "#777777",
	"warmGreyFourColor": "#8e8e8e",
	"warmGreyFiveColor": "#878787",
	"pinkishGreyThree": "#c9c9c9",
	"blackColor": "#000000",
	"mediumPinkTwo": "#ea5074",
	"darkPink": "#cf3256",
	"darkSixColor": "#0c1322",
	"darkNineColor": "#162432",
	"pigPink": "#ec8da3",
	"veryLightPink": "#fffefe",
	"maudlinOysterColor": "#c0c0c0",
	"haplessSnowColor": "#e2e2e2",
	"mercuryColor": "#e1e1e1",
	"pinkishGreyTwoColor": "#bcbcbc",
	"midnightColor": "#030a1b",
	"paleGreyThreeColor": "#f3f7fb",
	"paleGreyFourColor": "#ebf0f4",
	"porcelainColor": "#E9EAEB",
	"blackSqueezeColor": "#F2F7FB",
	"catskillWhite": "#DFE9F0",
	"redRibbonColor": "#E90242",
	"trueGreenColor": "#199800",
	"greyishColor": "#a3a3a3",
	"blueGreyThreeColor": "#6c839c",
	"slateThreeColor": "#425568",
	"blueGreyFourColor": "#687e97",
	"fiordColor": "#394D60",
	"darkSevenColor": "#101b25",
	"veryLightPinkColor": "#fffefe",
	"tomatoColor": "#eb2d2d",
	"bermudaGreyColor": "#6D839D",
	"paleCornflowerBlueColor": "#C8E3F9",
	"tangaroaColor": "#0F1C26",
	"hokiColor": "#68859E",
	"slateFourColor": "#4d6074",
	"greyBlueTwoColor": "#6a8199",
	"darkEightColor": "#13202c",
	"whiteColor": "#f1f1f1",
	"black05Color": "rgba(0, 0, 0, 0.05)",
	"black10Color": "rgba(0, 0, 0, 0.1)",
	"black15Color": "rgba(0, 0, 0, 0.15)",
	"black25Color": "rgba(0, 0, 0, 0.25)",
	"black20Color": "rgba(0, 0, 0, 0.2)",
	"black40Color": "rgba(0, 0, 0, 0.4)",
	"black55Color": "rgba(0, 0, 0, 0.55)",
	"black50Color": "rgba(0, 0, 0, 0.5)",
	"black60Color": "rgba(0, 0, 0, 0.6)",
	"whiteThreeColor": "#d8d8d8",
	"whiteFourColor": "#f9f9f9",
	"whiteSixColor": "#fcfcfc",
	"whiteTwo8Color": "rgba(255, 255, 255, 0.08)",
	"dodgerBlueColor": "#2f8fff",
	"blueGreyColor": "#7088a1",
	"blueGrey50Color": "rgba(112, 136, 161, 0.5)",
	"blueGreyTwoColor": "#788fa6",
	"bermudaGrayColor": "#7088A1",
	"blueyGreyColor": "#a3afbc",
	"blueyGrey60Color": "rgba(112, 136, 161, 0.6)",
	"clearBlueColor": "#1584fc",
	"darkGreyBlueColor": "#283949",
	"seafoamBlueColor": "#57bec7",
	"lightBlueColor": "#5ccff5",
	"babyBlueColor": "#b7d8ff",
	"cloudyBlue20Color": "rgba(185, 195, 206, 0.2)",
	"cloudyBlue30Color": "rgba(185, 195, 206, 0.3)",
	"cloudyBlue50Color": "rgba(185, 195, 206, 0.5)",
	"slate60Color": "rgba(86, 104, 122, 0.6)",
	"lightGreyColor": "#f8f9f9",
	"warmGreyColor": "#979797",
	"warmGrey5Color": "rgba(126, 126, 126, 0.05)",
	"warmGrey10Color": "rgba(126, 126, 126, 0.10)",
	"warmGrey15Color": "rgba(126, 126, 126, 0.15)",
	"lightGreenColor": "#42ffc0",
	"pinkishGreyColor": "#c8c8c8",
	"coralPinkColor": "#ff5c73",
	"macaroniAndCheeseColor": "#f8c233",
	"lipstickColor": "#e94149",
	"softPinkColor": "#f79dd0",
	"darkPeachColor": "#67b868",
	"cornFlowerColor": "#7850fc",
	"mediumPinkColor": "#e9579e",
	"paleRedColor": "#e54b4b",
	"darkTwoColor": "#1f2e3d",
	"silverColor": "#e4e5e5",
	"slateColor": "#56687a",
	"mortarColor": "rgba(91, 91, 91, 0.07)",
	"wisteriaColor": "#2d80eb",
	"wisteria15Color": "#2466bc",
	"wisteria15FlatColor": "#6ca6f1",
	"wisteria50Color": "#1b4d8d",
	"silverTwoColor": "#dedfdf",
	"darkGreyBlueTwoColor": "#293c4f",
	"bismarkColor": "#466784",
	"paleGreyColor": "#f3f3f4",
	"darkBlueGreyColor": "#111f2d",
	"darkThreeColor": "#1a2939",
	"darkFourColor": "#212035",
	"brownishGreyColor": "#636363",
	"greyish50Color": "rgba(177, 177, 177, 0.5)",
	"slateTwoColor": "#435567",
	"mangoColor": "#ffb02b",
	"darkMintColor": "#5cc984",
	"dodgerBlueTwoColor": "#56a0f6",
	"coralPinkTwoColor": "#ff575f",
	"duskColor": "#49627b",
	"gossamerColor": "#0ba765",
	"jungleGreenColor": "#25bc7c",
	"jetStreamColor": "#b2d5c9",
	"ghostColor": "#c6cbd1",
	"ironColor": "#dfe2e5",
	"aquaHazeColor": "#f6f8fa",
	"lochmaraColor": "#0576B9",
	"bahamaBlueColor": "#005E99",
	"tealishColor": "#37cfad",
	"darkSkyBlueColor": "#4A90E2",
	"outerSpaceColor90": "rgba(50, 60, 63, 0.9)",
	"brightBlueColor": "#007aff",
	"mediumCarmineColor": "#b63a3a",
	"orangePeelColor": "#f79a00",
	"greenHazeColor": "#009a59",
	"galleryColor": "#eaeaea",
	"elephantColor": "#10263c",
	"greyishBrownColor": "#4a4a4a",
	"blueyGreyTwo": "#a3b0be",
	"paleGreyTwoColor": "#dbe3ec",
	"metallicBlueColor": "#4c6580",
	"steelTwoColor": "#7c8895"
};