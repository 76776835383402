export default {
  400001: 'Unexpected content type `{0}`. Only {1} is allowed.',
  400002: 'Passed JSON data could not be deserialized: {0}',
  400003: "{0} object's attribue `{1}` is required.",
  400004: "The entity {0} with id = `{1}` already belongs to {2}'s {3}",
  400005: "The entity {0} with id = `{1}` does not belong to {2}'s {3}",
  400006: 'This type of request poses additional requirements on request content which were not met',
  400007: 'User with email `{0}` already exists',
  400008: 'Parameter `{0}` is required',
  400009: 'Validation failed for properties: {0}',
  400010: 'Attribute `{0}` has incorrect type',
  400011: 'Cannot add message to object of type `{0}`',
  400012: 'Search criteria too general. Refer to documentation for details.',
  400013: 'Incorrect value {0} for parameter {1}',
  400014: 'OpenID binding already exists',
  401001: 'Unable to authenticate. Incorrect email or password.',
  401002: 'We were unable to authenticate your request. Did you pass correct value for `type` parameter?',
  401003: 'ApiToken is missing from this request. Obtain apiToken at {0} first.',
  401004: 'The token you provided is not valid',
  401005: 'Provided ID Token is not valid',
  401006: 'Identity provider is not supported',
  403001: 'You have insufficient privileges to perform this action.',
  403002: "Selected organization's payment plan does not support this feature.",
  403003: 'Password change must be confirmed by providing `currentPassword` or valid `resetPasswordToken`',
  404001: 'Unknown resource `{0}`',
  404002: '{0} with {1} = `{2}` not found',
  405001: 'Resource `{0}` does not implement method `{1}`',
  406001: "Content served by this resource is not acceptable by client (see your request's Accept header)",
  500001: "I don't know how to srialize object of class {0}",
  500002: 'Database document malformed for id `{0}`',
  500003: 'Cannot map object of type or class `{0}`',
  500004: 'Directory `{0}` is not writable',
  500005: 'Proxy class `{0}` cannot be loaded',
  500006: 'File`{0}` is not writable',
  500007: 'File`{0}` is not readable',
  500999: 'Internal server error',
  501001: 'The resource or method you are trying to access is not implemented',

}
